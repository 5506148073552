import API from "./api";

/**
 * Class to manage intervention
 */
class InterventionService {
  constructor() {
    const api = new API();
    this.api = api;
  }

  successCallback = (status, data) => {
    return { status, data };
  };

  /**
   * Method used to reset user password
   *
   * @param interventionModel newInter
   */
  postCreateIntervention = async (newInter) => {
    const { data } = await this.api
      .post("/supercotrolia/interventions", newInter, this.successCallback)
      .catch((err) => {
        throw err;
      });
    return data;
  };

  /**
   * Method used to save an intervention
   *
   * @param interventionModel newInter
   */
  postSaveIntervention = async (newInter) => {
    const { data } = await this.api
      .post("/supercotrolia/interventions/save", newInter, this.successCallback)
      .catch((err) => {
        throw err;
      });
    return data;
  };

  /**
   * Get details of a saved intervention
   *
   * @param {int} id        Intervention id
   * @returns
   */
  getSavedInterventionDetails = async (id) => {
    const { data } = await this.api
      .get("/supercotrolia/interventions/save?id=" + id, this.successCallback)
      .catch((err) => {
        throw err;
      });
    return data;
  };

  /**
   * Get details of an intervention
   *
   * @param {int} id        Intervention id
   * @returns
   */
  getIntervention = async (id) => {
    const { data } = await this.api
      .get("/supercotrolia/interventions/" + id, this.successCallback)
      .catch((err) => {
        throw err;
      });
    return data;
  };

  /**
   * Get Latest intervention
   *
   * @returns
   */
  getLastIntervention = async () => {
    const { data } = await this.api
      .get("/supercotrolia/interventions/latest", this.successCallback)
      .catch((err) => {
        throw err;
      });
    return data;
  };

  /**
   * Get details of an intervention propsal
   *
   * @param {int} id        Intervention id
   * @returns
   */
  getInterventionProposal = async (id) => {
    const { data } = await this.api
      .get(
        "/supercotrolia/interventions/" + id + "/proposal",
        this.successCallback
      )
      .catch((err) => {
        throw err;
      });
    return data;
  };

  /**
   * Get details of an intervention invoice
   *
   * @param {int} id        Intervention id
   * @returns
   */
  getInterventionInvoice = async (id) => {
    const { data } = await this.api
      .get(
        "/supercotrolia/interventions/" + id + "/invoice",
        this.successCallback
      )
      .catch((err) => {
        throw err;
      });
    return data;
  };

  /**
   *
   * Mark a proposal as read
   *
   * @param {int} id    Intervention id
   * @returns
   */
  markPropalAsRead = async (id) => {
    const { data } = await this.api
      .post(
        "/supercotrolia/interventions/" + id + "/proposal/accept",
        {},
        this.successCallback
      )
      .catch((err) => {
        throw err;
      });
    return data;
  };

  /**
   *
   * Mark a proposal as refused
   *
   * @param {int} id    Intervention id
   * @returns
   */
  markPropalAsRefused = async (id, withReturn = false) => {
    const { data } = await this.api
      .post(
        "/supercotrolia/interventions/" + id + "/proposal/refuse",
        {
          withReturn,
        },
        this.successCallback
      )
      .catch((err) => {
        throw err;
      });
    return data;
  };

  /**
   * Set the intervention payment method
   *
   * @param {number}  id                  Intervention id
   * @param {number}  paymentMethod       Payment method
   * @returns
   */
  setPaymentMethod = async (id, paymentMethod) => {
    const { data } = await this.api
      .post(
        "/supercotrolia/interventions/" + id + "/invoice",
        {
          paymentMethod,
        },
        this.successCallback
      )
      .catch((err) => {
        throw err;
      });

    return data;
  };

  /**
   * Method used to list all interventions for a company
   *
   * @param {int}   limit         Limit the number of results
   * @param {int}   page          Page
   * @param {array} search        Search fields
   * @param {array} order         Order fields
   * @returns
   */
  listIntervention = async (limit, page, search, order) => {
    const response = await this.api
      .post(
        "/supercotrolia/interventions/search",
        {
          limit,
          page,
          search,
          order,
        },
        this.successCallback
      )
      .catch((err) => {
        throw err;
      });
    return response;
  };

  /**
   * Method used to list all interventions historic for a company
   *
   * @param {int}   limit         Limit the number of results
   * @param {int}   page          Page
   * @param {array} search        Search fields
   * @param {array} order         Order fields
   * @returns
   */
  listHistoricIntervention = async (limit, page, search, order) => {
    const response = await this.api
      .post(
        "/supercotrolia/interventions/historic/search",
        {
          limit,
          page,
          search,
          order,
        },
        this.successCallback
      )
      .catch((err) => {
        throw err;
      });
    return response;
  };

  /**
   * Method used to list all closed interventions for a company
   *
   * @param {array} search        Search fields
   * @param {int}   limit         Limit the number of results (default: 10)
   * @returns
   */
  listOldIntervention = async (search, limit = 10) => {
    const { data } = await this.api
      .get(
        "/supercotrolia/interventions/closed/search?search=" +
          search +
          "&limit=" +
          limit,
        this.successCallback
      )
      .catch((err) => {
        throw err;
      });
    return data;
  };

  /**
   * Method to encode on base64 a File object
   *
   * @param {File} file           File object to encode
   */
  toBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  /**
   * Upload a document for an intervention
   *
   * @param {string}  id          Intervention id
   * @param {File}    file        File object
   * @returns
   */
  uploadDocument = async (id, file) => {
    // Get the file content formated as base64
    const fileContent = await this.toBase64(file);

    const response = await this.api
      .post(
        "/supercotrolia/interventions/" + id + "/document",
        {
          filename: file.name,
          filecontent: fileContent,
        },
        this.successCallback
      )
      .catch((err) => {
        throw err;
      });
    return response;
  };

  /**
   * Upload a document for an intervention
   *
   * @param {string}  id          Intervention id
   * @param {File}    file        File object
   * @returns
   */
  deleteDocument = async (id, file) => {
    const response = await this.api
      .delete(
        "/supercotrolia/interventions/" +
          id +
          "/document?filename=" +
          file.name,
        this.successCallback
      )
      .catch((err) => {
        throw err;
      });
    return response;
  };
}

export default new InterventionService();
